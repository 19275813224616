import React from "react";
import FlorDeLuto from './FlorDeLuto'
import HablaConmigo from './HablaConmigo'
/* import Volver from './Volver'*/
import ItsHere from "./ItsHere";

export default function Music() {

    return(
        <div className="games-text-cell">
            <p style={{textAlign: 'center'}}>
                I've released an EP, an Album, and several singles.
                This is some of my work:
            </p>

            <table className="music-table">
                <tbody>
                    <tr>
                        <td className="music-cell">
                            <FlorDeLuto />
                        </td>
                        <td className="music-cell">
                            <HablaConmigo />
                        </td>
                        <td className="music-cell">
                            <ItsHere />
                           {/* <Volver /> */}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}